@import "../../app/assets/stylesheets/normalize.scss";
@import "../../app/assets/stylesheets/grailed/config/_constants.scss";
@import "../../app/assets/stylesheets/grailed/config/_colors.scss";
@import "../../app/assets/stylesheets/grailed/config/_fonts.scss";
@import "../../app/assets/stylesheets/grailed/config/_tailwind.scss";

:root {
  // TODO: this dies with skeleton, make this 16px
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: auto;
}
